import App from "next/app";
import dynamic from "next/dynamic";
import "assets/css/media_query.css";
import "assets/css/bootstrap.css";
import "assets/css/owl.carousel.css";
import "assets/css/owl.theme.default.css";
import "assets/css/style.scss";
import "assets/css/ck_edit.css";
import "nprogress/nprogress.css";
import "assets/css/style_1.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import "assets/css/customlogostyle.css";
import "assets/css/player.css";
import "/assets/css/register.css";
import { createContext, useContext } from "react";
import { fetchAPI } from "lib/api";
import { LanguageProvider } from "locale/Translate";
import GoTop from "components/Shared/GoTop";
import Head from "next/head";
import { getStrapiMedia } from "lib/media";

// Store Strapi Global object in context
export const GlobalContext = createContext({});
export const LocaleContext = createContext({});
export const useGlobalContext = () => useContext(GlobalContext);

const TopProgressBar = dynamic(
  () => {
    return import("components/Shared/TopProgressBar");
  },
  { ssr: false }
);

const MyApp = ({ Component, pageProps }) => {
  const { global, locale, locales, defaultLocale } = pageProps;

  return (
    <>
      <Head>
        <title>{global.siteName}</title>
        <link rel="shortcut icon" href={getStrapiMedia(global.favicon)} />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <LanguageProvider {...{ locale, locales, defaultLocale }}>
        <GlobalContext.Provider value={global}>
          <TopProgressBar />
          <Component {...pageProps} />
        </GlobalContext.Provider>
        <GoTop />
      </LanguageProvider>
    </>
  );
};

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So article, category and home pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (context) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const locale = context.ctx.locale || "vi";
  const locales = context.ctx.locales;
  const defaultLocale = context.ctx.defaultLocale;
  // console.log(locale, locales, defaultLocale, context);
  const appProps = await App.getInitialProps(context);
  // Fetch global site settings from Strapi
  const global = await fetchAPI("/global?_locale=" + locale);
  // Pass the data to our page via props
  return { ...appProps, pageProps: { global, locale, locales, defaultLocale } };
};

export default MyApp;
