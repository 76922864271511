import * as Sentry from "@sentry/browser";
import { getAsync } from "./request";
import qs from "qs";
export function getStrapiURL(path = "") {
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
  }${path}`;
}

// Helper to make GET requests to Strapi
export async function fetchAPI(path) {
  const requestUrl = getStrapiURL(path);
  try {
    const response = await getAsync(requestUrl);
    const data = response.data;
    return data;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
}

export async function fetchSinglePage(slug) {
  const path = "/pages?slug=" + slug;
  const pages = await fetchAPI(path);
  if (!pages.length) {
    return null;
  }
  return pages[0];
}
export async function fetchSinglePagePreview(slug) {
  const path =
    "/pages?slug=" +
    slug +
    `?secret=${process.env.NEXT_PUBLIC_PREVIEW_SECRET_TOKEN}`;
  const pages = await fetchAPI(path);
  if (!pages.length) {
    return null;
  }
  return pages[0];
}

export async function fetchNews(
  categorySlug = null,
  sort = "created_at:desc",
  page = 0
) {
  const RECORD_PER_PAGE = 5;
  let path = "/articlesByCategory";

  if (categorySlug) {
    path += "/" + categorySlug;
  }

  path += "?_sort=" + sort;
  path += "&_start=" + page * RECORD_PER_PAGE;
  path += "&_limit=" + RECORD_PER_PAGE;

  return fetchAPI(path);
}

export async function fetchNewsByTag(
  tagSlug = null,
  sort = "created_at:desc",
  page = 0
) {
  const RECORD_PER_PAGE = 4;
  let path = "/articlesByTag";

  if (tagSlug) {
    path += "/" + tagSlug;
  }

  path += "?_sort=" + sort;
  path += "&_start=" + page * RECORD_PER_PAGE;
  path += "&_limit=" + RECORD_PER_PAGE;

  return fetchAPI(path);
}

export async function fetchSingleArtical(slug) {
  const path = "/articles/" + slug;
  return fetchAPI(path);
}
export async function fetchSingleArticalPreview(slug) {
  const path = `/articles/preview/${slug}?secret=${process.env.NEXT_PUBLIC_PREVIEW_SECRET_TOKEN}`;
  return fetchAPI(path);
}

export async function getRegisteredMember(
  limit = 10,
  start = 0,
  type = "organization"
) {
  const path = "/member-registrations";
  const count = await fetchAPI(`${path}/count?type=${type}`);
  const response = await fetchAPI(
    `${path}?_limit=${limit}&_start=${start}&type=${type}&state=confirmed`
  );
  response &&
    response.map((r, index) => {
      r.stt = index + 1 + start;
      return r;
    });

  return {
    response,
    count,
  };
}

export async function fetchAllAthletesByPage(
  sort = "id:DESC",
  page = 0,
  game = "",
  country = "",
  q = ""
) {
  const RECORD_PER_PAGE = 8;
  let path = "/athlet?isWeb=true&status=true";
  // let path = "/athlet?";

  path += "&_sort=" + sort;
  path += "&_start=" + page * RECORD_PER_PAGE;
  path += "&_limit=" + RECORD_PER_PAGE;
  path += game ? "&role_and_games.game=" + game : "";
  path += country ? "&country=" + country : "";
  // path += q ? "&nickname_contains=" + q : ""
  // path += q ? "&_q=" + q : ""
  const query = qs.stringify({
    _where: { _or: [{ nickname_contains: q }, { fullname_contains: q }] },
  });
  path += q ? `&${query}` : "";
  return fetchAPI(path);
}
export async function fetchAllAthletes(game = "", country = "", q = "") {
  let path = "/athlet?isWeb=true&status=true&_limit=-1";
  // let path = "/athlet?_limit=-1";
  path += game ? "&role_and_games.game=" + game : "";
  path += country ? "&country=" + country : "";
  // path += q ? "&nickname_contains=" + q : "";
  // path += q ? "&_q=" + q : "";
  const query = qs.stringify({
    _where: { _or: [{ nickname_contains: q }, { fullname_contains: q }] },
  });
  path += q ? `&${query}` : "";
  return fetchAPI(path);
}

export async function fetchGames(sort = "id:DESC") {
  // let path = "/games?isWeb=true";
  let path = "/games?";

  path += "&_sort=" + sort;
  return fetchAPI(path);
}
export async function fetchCountries(sort = "country:ASC") {
  // let path = "/country-codes?isWeb=true";
  let path = "/country-codes?_limit=-1";

  path += "&_sort=" + sort;
  return fetchAPI(path);
}

export async function fetchAtheleById(id) {
  let path = `/athlet/${id}?isWeb=true`;
  return fetchAPI(path);
}
export async function fetchTeams() {
  let path = "/athlet-teams";
  return fetchAPI(path);
}
