import { createContext, memo, useContext } from "react";

export const LocaleContext = createContext();

export const useLanguageContext = () => useContext(LocaleContext);

export const LanguageProvider = memo(
  ({ children, locale, locales, defaultLocale }) => {
    const messages = require(`locale/${locale}.json`);
    return (
      <LocaleContext.Provider
        value={{ messages, locale, locales, defaultLocale }}
      >
        {children}
      </LocaleContext.Provider>
    );
  }
);

const Translate = ({ id }) => {
  const { messages } = useLanguageContext();
  return <>{messages[id] || id}</>;
};

export default Translate;
