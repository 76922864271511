import { getStrapiURL } from "./api";

export function getStrapiMedia(media, format = "small") {
  if (!media) {
    return false;
  }
  let imageUrl;

  if (media?.formats && media?.formats[format]) {
    imageUrl = media.formats[format].url;
  } else {
    imageUrl = media.url;
  }

  return imageUrl?.startsWith("/") ? getStrapiURL(imageUrl) : imageUrl;
}
